import React from "react";
import { AuthContext } from "./auth";
import "./App.css";
import "../node_modules/video-react/dist/video-react.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SplashPage from "./pages/SplashPage";
import LogupPage from "./pages/LogupPage";
import LoginPage from "./pages/LoginPage";
import NewsPage from "./pages/NewsPage";
import PartnerPage from "./pages/PartnerPage";
import HomePage from "./pages/HomePage";
import DetailPage from "./pages/DetailPage";
import PanelPage from "./pages/PanelPage";
import PrivateRoute from "./components/PrivateRoute";
import PagePage from "./pages/PagePage";
import ControlePage from "./pages/ControlePage";
import GalleryPage from "./pages/GalleryPage";

function App() {
  const [authTokens, setAuthTokens] = React.useState(
    sessionStorage.getItem("userId")
  );
  const setTokens = (data) => {
    setAuthTokens(data);
  };

  const [role, setRole] = React.useState(sessionStorage.getItem("userRole"));
  const setRoles = (data) => {
    setRole(data);
  };

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        setAuthTokens: setTokens,
        role,
        setRole: setRoles,
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={SplashPage} />
          <Route path="/logup" component={LogupPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/news" component={NewsPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/partners" component={PartnerPage} />
          <Route path="/page/:id" component={PagePage} />
          <Route path="/stands" component={HomePage} />
          <Route path="/panels" component={PanelPage} />

          <PrivateRoute path="/controles" component={ControlePage} />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
