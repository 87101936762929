import React from "react";
import Header from "../../components/Header";
import New from "../../components/Item/New";
import work from "../../assets/work.svg";
import Metier from "../../components/Item/Metier";
import a3 from "../../assets/box.svg";

import { Link } from "react-router-dom";
import Paragraph from "../../components/Paragraph";
import Footer from "../../components/Footer";
import SMF from "../../api";

const SplashPage = () => {
  const [article, setArticle] = React.useState([]);
  const [metier, setMetier] = React.useState([]);
  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Article.get()
      .then((res) => {
        if (res.data) {
          setArticle(res.data);
        }
      })
      .catch(() => {});
    SMF.Metier.get()
      .then((res) => {
        if (res.data) {
          setMetier(res.data);
        }
      })
      .catch(() => {});
  }

  return (
    <div>
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <h1>
              <b>
                <span className="color-default">
                  Salon des metiers et de la formations
                </span>
                <br /> 4 ème édition
              </b>
            </h1>
            <Link class="btn bg-default text-white mt-4" to="/login">
              <b>PARTICIPER</b>
            </Link>
          </div>
          <div className="col-lg-6">
            <img src={work} width="400" />
          </div>
        </div>
        <div className="dropdown-divider" />
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <img src={""} class="card-img-top" />
          </div>
          <div className="col-lg-6">
            <Paragraph limit={300}>
              La Fédération des entreprises du Congo a organisé, du 24 au 26
              octobre 2019, sous la modération de monsieur Jean-Luc MPOYO, la
              3ème édition du salon des Métiers et de la Formation sous le thème
              : « la promotion de la main-d’œuvre locale : enjeux et défis ».
              Cette troisième édition du salon des métiers et de la Formation a
              connu la participation des experts venus de la Belgique, des
              délégués et autorités politico-administratives des provinces sœurs
              du Lualaba, la TSHOPO, Sud-Oubangui, le Kasaï-central ainsi que
              des élèves pour la participation aux compé- titions des métiers.
              Il convient de signaler que le Gouvernement central était
              représenté par Monsieur Michel de la croix BATEBE, Conseiller du
              ministre National de l’enseignement primaire, secondaire et
              technique en charge des réformes de l’enseignement technique.
              Trois temps forts ont émaillé la tenue de cette 3ème édition : 44
              stands d’exposition pour 13 sociétés commerciales, 24 écoles et 7
              institutions d’appui à l’enseignement et la formation
              professionnelle ; 4 panels avec 24 intervenants ainsi que les
              compétions pour 19 métiers reparties sur 4 sites dont le CDR,
              l’INPP, le complexe hypnose et la salle victoire de la FEC. Au
              total, cette troisième édition a enregistré la participation de
              près de 5.000 personnes. Conférenciers, délégués d’entreprises,
              parents, élèves, etc.
            </Paragraph>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <Paragraph limit={300}>
              Par ailleurs, la FEC/HAUT-KATANGA a réali- sé une étude avec
              l’appui financier du Projet EDUKAT, qui consistait à
              l’identification des compétences de base et technique afin de
              mieux orienter l’offre des formations formelle et/ou non formelle
              pour son propre compte et celui du Centre de Ressource et aussi de
              l’INPP. C’est dans ce cadre que l’initiative de mettre en place
              une plateforme d’échanges sur cette grande problématique a été
              réalisée en collaboration avec la Division Provinciale de
              l’Enseignement Primaire, Secondaire et Professionnel, le CDR et la
              FEC par l’orga- nisation successivement de deux éditions 2017 et
              2018 du Salon des métiers et de la formation, SMF en sigle qui ont
              connu un franc succès Ce salon est considéré à ce jour comme un
              événement à rééditer chaque année en vue de réduire autant que
              faire se peut ces écarts sus évoqués. Et se fixe comme objectif
              principal de promouvoir une plate- forme/concours d’échanges et de
              partages entre les demandeurs d’emploi, les em- ployeurs, les
              apprenants, les instituts supé- rieurs et universitaires ainsi que
              les écoles techniques afin de soutenir une meilleure
              adéquation/formation emploi.
            </Paragraph>
          </div>
          <div className="col-lg-6">
            <img src={""} class="card-img-top" />
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <h6>
          <b>ACTUALITÉS</b>
        </h6>
        <div className="row mt-4">
          {article.slice(0, 6).map((el, key) => {
            return <New className="col-lg-6" title={el.title} description={el.description} cover={el.cover} date={el.created_at}/>;
          })}
        </div>
      </div>

      <div className="container mt-4 ">
        <h6>
          <b>LES MÉTIERS EN VALEUR</b>
        </h6>
        <div className="row  mt-4">
          {metier.map((el, key) => {
            return <Metier name={el.name} className="col-lg-3 mb-3" />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SplashPage;
