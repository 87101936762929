import React from "react";
import Skeleton from "react-loading-skeleton";
import SMF from "../../../api";
import { useParams } from "react-router-dom";

const Document = ({ name, description, link, ...props }) => {
  return (
    <div {...props}>
      <div className="card">
        {link && <img src={"http://160.153.253.111:3333/api/v1/file/documents/"+link} class="card-img-top" width="100" alt={name} />}

        <div className="card-body">
          <h5 className="card-title">
            <b>{name}</b>
          </h5>

          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

const DocumentSkeleton = (props) => {
  return (
    <div {...props}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Skeleton width={100} />
          </h5>
          <p className="card-text">
            <Skeleton />
          </p>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    onGet(id);
  }, []);

  function onGet(params) {
    SMF.Document.mine(params)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad(true);
    SMF.Document.post(formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }

  function onDelete(e) {}

  return (
  

      <div className="row mt-3">
        {loader ? (
          <>
            <DocumentSkeleton className=" col-lg-4 mb-2" />
            <DocumentSkeleton className=" col-lg-4 mb-2" />
            <DocumentSkeleton className=" col-lg-4 mb-2" />
          </>
        ) : data ? (
          data.length > 0 &&
          data.map((el, key) => {
            return (
              <Document
                name={el.name}
                description={el.description}
                id={el.id}
                link={el.link}
                className=" col-lg-4 mb-2"
              />
            );
          })
        ) : null}
      </div>
    
  );
};

export default Home;
