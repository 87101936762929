import React from "react";
import { Link } from "react-router-dom";
import box from "../../../assets/box.svg";

const Stand = (props) => {
  const { name, id, content, logo } = props;
  return (
    <div {...props}>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <img
                src={
                  logo
                    ? "http://160.153.253.111:3333/api/v1/file/logos/" + logo
                    : box
                }
                width="80"
              />
            </div>
            <div className="col ml-4">
              <h5 class="card-title">{name}</h5>
              <p dangerouslySetInnerHTML={{ __html: content && content.slice(0, 20) }}/>
              <Link
                to={"/page/" + id + "?tab=home"}
                class="btn btn-primary btn-sm"
              >
                Visitez
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stand;
