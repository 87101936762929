import React from "react";
import SMF from "../../../api";
import { Button, Modal } from "../../../ui/barboza";
import Skeleton from "react-loading-skeleton";
import CKEditor from "ckeditor4-react";

const About = () => {
  const [data, setData] = React.useState();
  const [edit, setEdit] = React.useState()

  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);
  const [isLoad1, setIsLoad1] = React.useState(false);

  React.useEffect(() => {
    onGet(sessionStorage.getItem("userStand"));
  }, []);

  function onGet(params) {
    SMF.Stand.mine(params)
      .then((res) => {
        setData(res.data);
        setEdit(res.data.apropos)
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    if (!e.target.cover.value) {
      formData.delete("cover")
    }
    
    formData.append("apropos", edit)
    setIsLoad(true);
    SMF.Stand.put(sessionStorage.getItem("userStand"), formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }
  function onUpdate(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad1(true);
    SMF.User.put(sessionStorage.getItem("userId"), formData)
      .then((res) => {
        setIsLoad1(false);
      })
      .catch(() => {
        setIsLoad1(false);
      });
  }

  function onEditorChange(evt) {
   
    setEdit(evt.editor.getData());
  }

  return (
    <div className="col">
      <nav class="navbar navbar-expand-lg navbar-light borderBottom mt-2">
        <a class="navbar-brand" href="#">
          À propos
        </a>
      </nav>
      <div>
        <form onSubmit={onUpdate} className="mb-5">
          <div class="form-group">
            <label for="exampleFormControlFile1">Logo de la société</label>
            <input
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              name="logo"
            />
          </div>
          <Button className="btn btn-primary" onLoad={isLoad1}>
            Enregistrer
          </Button>
        </form>
        <form onSubmit={onAdd}>
          <div class="form-group">
            <label for="exampleFormControlFile1">
              Votre site web si vous en avez un
            </label>
            <input
              type="text"
              class="form-control-file"
              id="exampleFormControlFile1"
              name="link"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlFile1">Votre image de fond </label>
            <input
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              name="cover"
            />
          </div>
          <div class="form-group">
       {edit && <CKEditor data={edit} onChange={onEditorChange} />}   
          </div>
          <Button className="btn btn-primary" onLoad={isLoad}>
            Enregistrer
          </Button>
        </form>
      </div>
    </div>
  );
};

export default About;
