import React from "react";
import SMF from "../../../api";
import { Button, Modal } from "../../../ui/barboza";
import Skeleton from "react-loading-skeleton";

const Image = ({ name, link, ...props }) => {
  return (
    <div {...props}>
      <div class="card bg-dark text-white">
        <img src={link && "http://160.153.253.111:3333/api/v1/file/gallerys/" + link} class="card-img" alt={name} />
        <div class="card-img-overlay">
          <h5 class="card-title">{name}</h5>
        </div>
      </div>
    </div>
  );
};

const ImageSkeleton = (props) => {
  return (
    <div {...props}>
      <Skeleton height={200} />
    </div>
  );
};

const Album = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);

  React.useEffect(() => {
    onGet(sessionStorage.getItem("userStand"));
  }, []);

  function onGet(params) {
    SMF.Gallery.mine(params)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad(true);
    SMF.Gallery.post(formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }

  return (
    <div className="col">
      <nav class="navbar navbar-expand-lg navbar-light borderBottom mt-2">
        <a class="navbar-brand" href="#">
          Gallérie
        </a>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <Button
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#add"
            >
              Ajouter
            </Button>
            <Modal
              className="modal fade"
              id="add"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              title="Ajouter des produits ou services "
            >
              <form onSubmit={onAdd}>
                <div class="form-group">
                  <label for="name">Nom</label>
                  <input
                    type="name"
                    class="form-control"
                    id="name"
                    required
                    name="name"
                  />
                </div>

                <div class="form-group">
                  <label for="link">
                    Image ou logo accompagnant (optionnelle)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="link"
                    name="link"
                  />
                </div>
                <input
                  type="hidden"
                  value={sessionStorage.getItem("userStand")}
                  name="stands_id_stands"
                />

                <Button
                  type="submit"
                  class="btn btn-primary col"
                  onLoad={isLoad}
                >
                  Enregistrer
                </Button>
              </form>
            </Modal>
          </li>
        </ul>
      </nav>
      <div className="row mt-3">
        {loader ? (
          <>
            <ImageSkeleton className=" col-lg-4 mb-2" />
            <ImageSkeleton className=" col-lg-4 mb-2" />
            <ImageSkeleton className=" col-lg-4 mb-2" />
          </>
        ) : data ? (
          data.length > 0 &&
          data.map((el, key) => {
            return (
              <Image
                name={el.name}
                id={el.id}
                link={el.link}
                className=" col-lg-4 mb-2"
              />
            );
          })
        ) : null}
      </div>
    </div>
  );
};

export default Album;
