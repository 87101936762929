import React from 'react';
import metier from "../../../assets/metier.svg"

const Metier = (props) => {
  const {name,} = props
  return (
    <div {...props}>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <img src={metier} width="50"/>
            </div>
            <div className="col ml-4">
              <h6 class="card-title">{name}</h6>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Metier;
