import React from "react";
import SMF from "../../../api";
import { Button, Modal } from "../../../ui/barboza";
import Skeleton from "react-loading-skeleton";

const News = ({ name, description, link, ...props }) => {
  return (
    <div {...props}>
      <div className="card">
        {link && (
          <img
            src={"http://160.153.253.111:3333/api/v1/file/news/" + link}
            class="card-img-top"
            alt="..."
          />
        )}

        <div className="card-body">
          <h5 className="card-title">
            <b>{name}</b>
          </h5>

          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

const NewSkeleton = (props) => {
  return (
    <div {...props}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Skeleton width={100} />
          </h5>
          <p className="card-text">
            <Skeleton />
          </p>
        </div>
      </div>
    </div>
  );
};

const New = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);

  React.useEffect(() => {
    onGet(sessionStorage.getItem("userStand"));
  }, []);

  function onGet(params) {
    SMF.New.mine(params)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad(true);
    SMF.New.post(formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }

  return (
    <div className="col">
      <nav class="navbar navbar-expand-lg navbar-light borderBottom mt-2">
        <a class="navbar-brand" href="#">
          Publication
        </a>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <Button
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#add"
            >
              Ajouter
            </Button>
            <Modal
              className="modal fade"
              id="add"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              title="Ajouter des produits ou services "
            >
              <form onSubmit={onAdd}>
                <div class="form-group">
                  <label for="description">Votre contenu</label>
                  <input
                    type="text"
                    class="form-control"
                    id="description"
                    name="description"
                  />
                </div>

                <div class="form-group">
                  <label for="link">
                    Image ou logo accompagnant (optionnelle)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="link"
                    name="link"
                  />
                </div>
                <input
                  type="hidden"
                  value={sessionStorage.getItem("userStand")}
                  name="stands_id_stands"
                />

                <Button
                  type="submit"
                  class="btn btn-primary col"
                  onLoad={isLoad}
                >
                  Enregistrer
                </Button>
              </form>
            </Modal>
          </li>
        </ul>
      </nav>
      <div className="row mt-3">
        {loader ? (
          <>
            <NewSkeleton className=" col-lg-4 mb-2" />
            <NewSkeleton className=" col-lg-4 mb-2" />
            <NewSkeleton className=" col-lg-4 mb-2" />
          </>
        ) : data ? (
          data.length > 0 &&
          data.map((el, key) => {
            return (
              <News
                name={el.name}
                description={el.description}
                id={el.id}
                link={el.link}
                className=" col-lg-4 mb-2"
              />
            );
          })
        ) : null}
      </div>
    </div>
  );
};

export default New;
