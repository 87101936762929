import React from "react";
import Header from "../../components/Header";
import Panel from "../../components/Item/Panel";
import conf from "../../assets/conf.svg";
import Skeleton from "react-loading-skeleton";
import SMF from "../../api";

const PanelPage = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Panel.get()
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  return (
    <div>
      <Header />
      <div className="container ">
        <div className="row mt-5">
          <div className="col-lg-6">
            <h1>
              <b>
                <span className="color-default">Participez à nos panels</span>
                <br /> en ligne
              </b>
            </h1>
          </div>
          <div className="col-lg-6">
            <img src={conf} width="400" />
          </div>
        </div>
        <div className="dropdown-divider" />
      </div>
      <div className="container mt-4 mb-5">
        <div className="row">
          {loader ? (
            <>
              <div className="col-lg-6">
                <Skeleton height={300} />
              </div>
              <div className="col-lg-6">
                <Skeleton height={300} />
              </div>
            </>
          ) : (
            data.map((el, key) => {
              return <Panel data={el} className="col-lg-6" />;
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default PanelPage;
