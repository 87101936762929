import React from 'react';
import PropTypes from "prop-types";

const Modal = props => {

  const { children, title, size } = props;
  return (
    <div {...props}>
      <div class={"modal-dialog modal-dialog-scrollable" + size}>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  size: "",
};
export default Modal;
