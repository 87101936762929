import React from "react";
import Header from "../../components/Header";
import New from "../../components/Item/New";
import news2 from "../../assets/news2.svg";
import SMF from "../../api";
import Skeleton from "react-loading-skeleton";

const NewsPage = () => {
  const [article, setArticle] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Article.get()
      .then((res) => {
        if (res.data) {
          setArticle(res.data);
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <h1>
              <b>
                <span className="color-default">
                  Toute l'acualités et articles avec vous
                </span>
                <br />
              </b>
            </h1>
          </div>
          <div className="col-lg-6">
            <img src={news2} class="card-img w-100" />
          </div>
        </div>
        <div className="dropdown-divider" />
      </div>

      <div className="container mt-5">
        <div className="row mb-5">
          {loader ? (
            <>
              <div className="col-lg-6">
                <Skeleton height={150} />
              </div>

              <div className="col-lg-6">
                <Skeleton height={150} />
              </div>
            </>
          ) : (
            article.map((el, key) => {
              return <New className="col-lg-6" key={key} title={el.title} description={el.description} cover={el.cover} date={el.created_at}/>;
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
