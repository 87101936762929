import React from "react";

const Paragraph = (props) => {
  const [show, setShow] = React.useState(false);
  let element = "";
  const { children, limit } = props;
  if (children.length > limit) {
    if (show) {
      element = children;
    } else {
      let a = children.slice(0, limit);
      element = a + "...";
    }
  } else {
    element = children;
  }

  return (
    <div>
      <p>{element}</p>
      {children.length > limit && (
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={() => setShow((e) => !e)}
        >
          {show ? (
            "Voir moins "
          ) : (
            <>
              Voir plus <span className="fa fa-long-arrow-right" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default Paragraph;
