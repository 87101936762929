import React from "react";

const Gallery = (props) => {
  const { link, name } = props;
  return (
    <div {...props}>
      <div class="card bg-dark text-white">
        <img
          src={
            "http://160.153.253.111:3333/api/v1/file/gallery_sites/" + link
          }
          class="card-img w-100"
          alt={name}
          
        />
        <div class="card-img-overlay">
          <h5 class="card-title">{name}</h5>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
