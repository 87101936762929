import React from "react";
import logo from "../../assets/smf.png";
import PropTypes from "prop-types";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";

const Header = ({ help }) => {
  const { role, setAuthTokens } = useAuth();

  function logout() {
    sessionStorage.clear();
    setAuthTokens(sessionStorage.getItem("userId"));
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light borderBottom">
      <div className="container">
        <a class="navbar-brand" href="/">
          <img src={logo} alt="" width={60} class="img-fluid" />
        </a>
        {help ? null : (
          <>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <Link class="nav-link" to="/">
                    <b>ACCUEIL</b>
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link class="nav-link" to="/partners">
                    <b>PARTENAIRES</b>
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link class="nav-link" to="/stands">
                    <b>STANDS</b>
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link class="nav-link" to="/panels">
                    <b>PANELS</b>
                  </Link>
                </li>
                <li class="nav-item active">
                  <Link class="nav-link" to="/gallery?tab=photos">
                    <b>GALERIE</b>
                  </Link>
                </li>

                <li class="nav-item active">
                  <Link class="nav-link" to="/news">
                    <b>ACTUALITÉS</b>
                  </Link>
                </li>
                {role ? (
                  role == "entreprise" ? (
                    <>
                      <li class="nav-item active">
                        <Link class="nav-link" to="/controles?tab=home">
                          <b>PANEAUX DE CONTROLE</b>
                        </Link>
                      </li>
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {sessionStorage.getItem("userName")}{" "}
                          {sessionStorage.getItem("userPrenom")}
                        </a>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <a class="dropdown-item" href="#" onClick={logout}>
                            Déconnexion
                          </a>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {sessionStorage.getItem("userName")}{" "}
                          {sessionStorage.getItem("userPrenom")}
                        </a>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <a class="dropdown-item" href="#" onClick={logout}>
                            Déconnexion
                          </a>
                        </div>
                      </li>
                    </>
                  )
                ) : (
                  <li class="nav-item active">
                    <Link
                      class="nav-link btn bg-default text-white"
                      to="/login"
                    >
                      <b>PARTICIPER</b>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

Header.defaultProps = {
  help: false,
};
export default Header;
