import React from "react";
import SMF from "../../api";

const Package = (props) => {
  const { children, name, theme, onContinue, id, options } = props;
  
  return (
    <div {...props}>
      <div className="card">
        <div class="card-header" style={{ backgroundColor: theme }}>
          <b>{name}</b>
        </div>
        <div className="card-body">{children}</div>
        <dv className="card-footer bg-white ">
          <div className="row justify-content-center">
            <button
              type="button"
              onClick={() => onContinue(id)}
              class="btn btn-primary"
            >
              Commencer maintenant
            </button>
          </div>
        </dv>
      </div>
    </div>
  );
};

export default Package;
