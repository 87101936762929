import React from "react";

const Video = (props) => {
  const { link, name } = props;
  return (
    <div {...props}>
      <div class="card ">
        <video class="card-img w-100" autoplay>
          <source
            src={
              "http://160.153.253.111:3333/api/v1/file/gallery_sites/" +
              link
            }
          />
        </video>

        <div class="card-img-overlay">
          <h5 class="card-title">{name}</h5>
        </div>
      </div>
    </div>
  );
};

export default Video;
