import React from "react";
import SMF from "../../api";
import Header from "../../components/Header";
import Stand from "../../components/Item/Stand";

const HomePage = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(()=>{
    onGet()
  },[])

  function onGet() {
    SMF.Stand.get()
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {});
  }
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="row">
          {data
            ? data.map((el, key) => {
                return <Stand className="col-lg-4 mb-4" name={el.name} id={el.id_stands} content={el.apropos} logo={el.user.logo}/>;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
