import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header";
import { Button } from "../../ui/barboza";
import About from "./About";
import Home from "./Home";
import New from "./New";
import Album from "./Album";

const ControlePage = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {}, [query.get("tab")]);
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-lg-2  border-right" id="sidebar-wrapper">
            <ul class="nav flex-column">
              <li class="nav-item">
                <Link class="nav-link " to={"/controles?tab=home"}>
                  Accueil
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to={"/controles?tab=news"}>
                  Fil d'actualités
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to={"/controles?tab=gallery"}>
                  Gallerie
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to={"/controles?tab=about"}>
                  A propos
                </Link>
              </li>
            </ul>
          </div>
          
          {query.get("tab") == "home" ? (
            <Home />
          ) : query.get("tab") == "news" ? (
            <New />
          ) : query.get("tab") == "gallery" ? (
            <Album/>
          ) : query.get("tab") == "about" ? (
            <About />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ControlePage;
