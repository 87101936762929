import React from "react";
import Header from "../../components/Header";
import Partner from "../../components/Item/Partner";
import SMF from "../../api";
import pa from "../../assets/partener.svg";

const PartnerPage = () => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Stand.get()
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {});
  }
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <h1>
              <b>
                <span className="color-default">
                  Salon des metiers et de la formations
                </span>
                <br /> 4 ème édition nos partenaires
              </b>
            </h1>
          </div>
          <div className="col-lg-6">
            <img src={pa} width="400" />
          </div>
        </div>
        <div className="dropdown-divider" />
      </div>
      <div className="container">
        <div className="row mt-5">
          {data
            ? data.map((el, key) => {
                return <Partner className="col-lg-4" name={el.name} content={el.apropos} logo={el.user.logo} />;
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default PartnerPage;
