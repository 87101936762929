import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import SMF from "../../api";
import About from "./About";
import Gallery from "../PagePage/Gallery";
import Home from "./Home";
import New from "./New";
import box from "../../assets/box.svg";

import Header from "../../components/Header";

const PagePage = () => {
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    onGet(id);
  }, [id]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {}, [query.get("tab")]);

  function onGet(params) {
    SMF.Stand.detail(params)
      .then((res) => {
        setLoader(false);
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  }
  return (
    <div>
      <Header />
      <section className="bottomShadow">
        <div className="container">
          <div class="card bg-dark text-white ">
            <img
              src={
                "http://160.153.253.111:3333/api/v1/file/stands/" + data.cover
              }
              className="card-img"
              height="400"
            />
          </div>
          <div className="d-flex boxProfile">
            <img
              src={
                data.user
                  ? "http://160.153.253.111:3333/api/v1/file/logos/" + data.user.logo
                  : box
              }
              className="profile"
            />

            <div className="mt-5 ml-3">
              <h5>
                <b>{data.name}</b>
              </h5>
              <p>
                <b>
                  <a href={data.link}>{data.link}</a>
                </b>
              </p>
            </div>
          </div>
          <div>
            <nav className="nav mt-1 ">
              <Link
                className={
                  query.get("tab") == "home"
                    ? "nav-link b-secondary "
                    : "nav-link black-color"
                }
                to={"/page/" + id + "?tab=home"}
              >
                <b>Accueil</b>
              </Link>
              <Link
                className={
                  query.get("tab") == "news"
                    ? "nav-link b-secondary "
                    : "nav-link black-color"
                }
                to={"/page/" + id + "?tab=news"}
              >
                <b>Fil d'actualité</b>
              </Link>
              <Link
                className={
                  query.get("tab") == "gallery"
                    ? "nav-link b-secondary "
                    : "nav-link black-color"
                }
                to={"/page/" + id + "?tab=gallery"}
              >
                <b>Galerie</b>
              </Link>
              <Link
                className={
                  query.get("tab") == "about"
                    ? "nav-link b-secondary "
                    : "nav-link black-color"
                }
                to={"/page/" + id + "?tab=about"}
              >
                <b>À propos</b>
              </Link>
            </nav>
          </div>
        </div>
      </section>
      <div className="container mt-1 mb-5">
        {query.get("tab") == "home" ? (
          <Home />
        ) : query.get("tab") == "news" ? (
          <New />
        ) : query.get("tab") == "gallery" ? (
          <Gallery />
        ) : query.get("tab") == "about" ? (
          <p dangerouslySetInnerHTML={{ __html: data.apropos }}/>
        ) : null}
      </div>
    </div>
  );
};

export default PagePage;
