import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonImage = (props) => {
  return (
    <div {...props}>
      <Skeleton height={200} />
    </div>
  );
};

export default SkeletonImage;
