import React from "react";
import news from "../../../assets/news.svg";

const New = (props) => {
  const { title, description, date, cover } = props;
  return (
    <div {...props}>
      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img
              src={
                cover
                  ? "http://160.153.253.111:3333/api/v1/file/articles/" + cover
                  : news
              }
              class="card-img"
              height="140"
              alt=""
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{title}</h5>
              <p class="card-text">{description}</p>
              <p class="card-text">
                <small class="text-muted">{date}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
