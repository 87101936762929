import React from "react";
import SMF from "../../api";
import Header from "../../components/Header";
import Package from "../../components/Package";
import { Button } from "../../ui/barboza";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../../auth";

const LogupPage = () => {
  const [data, setData] = React.useState([]);
  const [display, setDisplay] = React.useState(1);
  const [type, setType] = React.useState(true);
  const [pack, setPack] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const { setAuthTokens, setRole } = useAuth();

  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Package.get()
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function onNext(params) {
    setDisplay(2);
    if (params === "visitor") {
      setType(true);
      setPack("visitor");
    } else {
      setType(false);
      setPack(params);
    }
  }
  function onAdd(e) {
    e.preventDefault();
    let formData = {};
    if (pack !== "visitor") {
      formData = {
        nom: e.target.nom.value,
        prenom: e.target.prenom.value,
        email: e.target.email.value,
        entreprise: e.target.entreprise.value,
        packs: [pack],
        entreprise_role: e.target.entreprise_role.value,
        password: e.target.confirmation.value,
        password_confirmation: e.target.password_confirmation.value,
      };
    } else {
      formData = {
        nom: e.target.nom.value,
        prenom: e.target.prenom.value,
        email: e.target.email.value,
      };
    }

    setLoader(true);
    SMF.User.post(JSON.stringify(formData))
      .then((res) => {
        setLoader(false);
        if (res.data) {
          if (res.status == 200) {
            setRedirect(true);
          }
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <Header help />
      <div className="container mt-5">
        <div className="row justify-content-center">
          {display == 1 ? (
            <>
              <Package
                className="col-lg-3"
                name="Visiteur"
                onContinue={() => onNext("visitor")}
              >
                <p>Option</p>
              </Package>
              {data
                ? data.map((el, key) => {
                    return (
                      <Package
                        className="col-lg-3"
                        style={{ backgroundColor: "" }}
                        name={el.name}
                        theme={el.theme}
                        id={el.id_packs}
                        
                        onContinue={onNext}
                      >
                      {
                        el.options? el.options.map((e, key)=>{
                          return <p>{e.name}</p>
                        }):null

                      }
                        
                      </Package>
                    );
                  })
                : null}
            </>
          ) : type ? (
            <div className="col-lg-5">
              <form onSubmit={onAdd}>
                <div class="form-group">
                  <label for="nom">Votre nom</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nom"
                    name="nom"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="prenom">Votre prénom</label>
                  <input
                    type="text"
                    class="form-control"
                    id="prenom"
                    name="prenom"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email">Votre adresse e-mail</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                    required
                  />
                </div>

                <Button
                  type="submit"
                  class="btn btn-primary col"
                  onLoad={loader}
                >
                  Enregistrer
                </Button>
              </form>
            </div>
          ) : (
            <div className="col-lg-5 mb-5">
              <form onSubmit={onAdd}>
                <h2>Information personnelles</h2>
                <div class="form-group">
                  <label for="nom">Votre nom</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nom"
                    name="nom"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="prenom">Votre prénom</label>
                  <input
                    type="text"
                    class="form-control"
                    id="prenom"
                    name="prenom"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="email">Votre adresse e-mail</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="password">Mot de passe</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    name="confirmation"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="password">Confirmation de mot passe</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    name="password_confirmation"
                    required
                  />
                </div>

                <h2 className="mt-5">Information sur l'entreprise</h2>

                {/* <div class="form-group">
                  <label for="logo">Le logo de l'entreprise</label>
                  <input
                    type="file"
                    class="form-control"
                    id="logo"
                    name="logo"
                  />
                </div> */}

                <div class="form-group">
                  <label for="entreprise">Nom de l'entreprise</label>
                  <input
                    type="text"
                    class="form-control"
                    id="entreprise"
                    name="entreprise"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="entreprise_role">
                    Votre fonction dans l'entreprise
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="entreprise_role"
                    name="entreprise_role"
                    required
                  />
                </div>

                <Button
                  type="submit"
                  class="btn btn-primary col"
                  onLoad={loader}
                >
                  Enregistrer
                </Button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogupPage;
