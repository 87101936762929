import React from "react";
import Iframe from "react-iframe";

const Panel = (props) => {
  const { data } = props;
  return (
    <div {...props}>
      <h4>{data.title}</h4>
      <Iframe
        url={data.youtube}
        width="450px"
        height="350px"
        id={data.id}
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default Panel;
