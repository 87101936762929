import React from "react";
import { Link, Redirect } from "react-router-dom";
import SMF from "../../api";
import Header from "../../components/Header";
import { Button } from "../../ui/barboza";
import { useAuth } from "../../auth";

const LoginPage = () => {
  const [loader, setLoader] = React.useState(false);
  const [display, setDisplay] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const { setAuthTokens, setRole } = useAuth();

  function onConnect(e) {
    e.preventDefault();
    setLoader(true);
    let formData = new FormData(e.target);
    SMF.Auth.auth(formData)
      .then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem("userRole", "entreprise");
          sessionStorage.setItem("userId", res.data.id_users);
          sessionStorage.setItem("userName", res.data.nom);
          sessionStorage.setItem("userPrenom", res.data.prenom);
          sessionStorage.setItem("userStand", res.data.stands[0].id_stands);
          setAuthTokens(res.data.id_users);
          setRole("entreprise");
          setRedirect(true);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onVerify(e) {
    e.preventDefault();
    setLoader(true);
    let formData = new FormData(e.target);
    SMF.Auth.verify(formData)
      .then((res) => {
        if (res.data.result) {
          if (res.data.roles == "participant") {
            sessionStorage.setItem("userRole", "participant");
            sessionStorage.setItem("userId", res.data.user.id_users);
            sessionStorage.setItem("userName", res.data.user.nom);
            sessionStorage.setItem("userPrenom", res.data.user.prenom);
            setAuthTokens(res.data.user.id_users);
            setRole("participant");
            setRedirect(true);
          } else {
            setDisplay(true);
          }
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }
  if (redirect) {
    return <Redirect to="/stands" />;
  }

  return (
    <div>
      <Header help />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="">
              <div className="card-body">
                <form onSubmit={display ? onConnect : onVerify}>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Adresse e-mail</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="email"
                    />
                  </div>
                  {display ? (
                    <div class="form-group">
                      <label for="password">Mot de passe</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        name="password"
                      />
                    </div>
                  ) : null}

                  <Button
                    type="submit"
                    class="btn bg-default text-white col"
                    onLoad={loader}
                  >
                    Connexion
                  </Button>
                </form>
                <div className="dropdown-divider mt-4" />

                <Link to="/logup" class="btn bg-blue text-white col mt-2">
                  S'enregistrer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
