import React from "react";

const Footer = () => {
  return (
    <footer class="footer mt-auto py-3" style={{ backgroundColor: "#37C837" }}>
      {/* <div class="container">
        <div className="col-lg-4">
          <h3 className="text-white">
            <b>CONTACT</b>
          </h3>
          <p className="text-white">‭+243 820 027 019‬</p>
          <p className="text-white">‭fec.kat @fec-rdc.com‬</p>
          <p className="text-white">www.fec-rdc.com</p>
          <pn className="text-white">
            FEC, Fédération des Entreprises du Congo, Haut-Katanga 550, Avenue
            Tabora / Kasaï / Lubumbashi
          </pn>
        </div>
        <div className="col-lg-4"></div>
      </div> */}
    </footer>
  );
};

export default Footer;
