import React from "react";
import SMF from "../../../api";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const Image = ({ name, link, ...props }) => {
  return (
    <div {...props}>
      <div class="card bg-dark text-white">
        <img
          src={
            link && "http://160.153.253.111:3333/api/v1/file/gallerys/" + link
          }
          class="card-img"
          alt={name}
        />
        <div class="card-img-overlay">
          <h5 class="card-title">{name}</h5>
        </div>
      </div>
    </div>
  );
};

const ImageSkeleton = (props) => {
  return (
    <div {...props}>
      <Skeleton height={200} />
    </div>
  );
};

const Gallery = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    onGet(id);
  }, []);

  function onGet(params) {
    SMF.Gallery.mine(params)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad(true);
    SMF.Gallery.post(formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }

  return (
    <div className="row mt-3">
      {loader ? (
        <>
          <ImageSkeleton className=" col-lg-4 mb-2" />
          <ImageSkeleton className=" col-lg-4 mb-2" />
          <ImageSkeleton className=" col-lg-4 mb-2" />
        </>
      ) : data ? (
        data.length > 0 &&
        data.map((el, key) => {
          return (
            <Image
              name={el.name}
              id={el.id}
              link={el.link}
              className=" col-lg-4 mb-2"
            />
          );
        })
      ) : null}
    </div>
  );
};

export default Gallery;
