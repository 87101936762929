import React from "react";
import SMF from "../../../api";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const News = ({ name, description, link, ...props }) => {
  return (
    <div {...props}>
      <div className="card">
        {link && (
          <img
            src={"http://160.153.253.111:3333/api/v1/file/news/" + link}
            class="card-img-top"
            alt="..."
          />
        )}

        <div className="card-body">
          <h5 className="card-title">
            <b>{name}</b>
          </h5>

          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

const NewSkeleton = (props) => {
  return (
    <div {...props}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            <Skeleton width={100} />
          </h5>
          <p className="card-text">
            <Skeleton />
          </p>
        </div>
      </div>
    </div>
  );
};

const New = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(false);
  const { id } = useParams();

  React.useEffect(() => {
    onGet(id);
  }, []);

  function onGet(params) {
    SMF.New.mine(params)
      .then((res) => {
        setData(res.data);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onAdd(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    setIsLoad(true);
    SMF.New.post(formData)
      .then((res) => {
        setIsLoad(false);
        onGet(sessionStorage.getItem("userStand"));
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }

  return (
    <div className="row mt-3 mb-5">
      {loader ? (
        <>
          <NewSkeleton className=" col-lg-4 mb-2" />
          <NewSkeleton className=" col-lg-4 mb-2" />
          <NewSkeleton className=" col-lg-4 mb-2" />
        </>
      ) : data ? (
        data.length > 0 &&
        data.map((el, key) => {
          return (
            <News
              name={el.name}
              description={el.description}
              id={el.id}
              link={el.link}
              className=" col-lg-4 mb-2"
            />
          );
        })
      ) : null}
    </div>
  );
};

export default New;
