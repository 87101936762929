import axios from "axios";

const Axios = axios.create({
  baseURL: "http://160.153.253.111:3000/api/v1",
});

const Auth = {
  auth(data) {
    return Axios.post("/auth", data);
  },
  verify(data) {
    return Axios.post("/verify", data);
  },
};

const User = {
  get() {},
  post(data) {
    return Axios.post("/register", data, {
      headers: {
        "content-type": "application/json",
      },
    });
  },
  put(id, data) {
    return Axios.put("/users/" + id, data);
  },
};

const Package = {
  get() {
    return Axios.get("/packs");
  },
  getOption(id) {
    return Axios.get("/packs_options?packs_id_paks=" + id);
  },
};

const Stand = {
  get() {
    return Axios.get("/stands");
  },
  detail(id) {
    return Axios.get("/stands/" + id);
  },
  put(id, data) {
    return Axios.put("/stands/" + id, data);
  },
  mine(id){
    return Axios.get("/stands/" + id);
  }
};

const Document = {
  get() {
    return Axios.get("/documents");
  },
  post(data) {
    return Axios.post("/documents", data);
  },
  mine(id) {
    return Axios.get("/documents?stands_id_stands=" + id);
  },
};

const New = {
  get() {
    return Axios.get("/news");
  },
  post(data) {
    return Axios.post("/news", data);
  },
  mine(id) {
    return Axios.get("/news?stands_id_stands=" + id);
  },
};

const Gallery = {
  get() {
    return Axios.get("/gallerys");
  },
  post(data) {
    return Axios.post("/my_gallerys", data);
  },
  mine(id) {
    return Axios.get("/my_gallerys?stands_id_stands=" + id);
  },
};

const Article = {
  get() {
    return Axios.get("/articles");
  },
};
const Metier = {
  get() {
    return Axios.get("/metiers");
  },
};

const Panel = {
  get() {
    return Axios.get("/panels");
  },
};

const SMF = {
  Auth,
  User,
  Package,
  Stand,
  Document,
  New,
  Gallery,
  Article,
  Metier,
  Panel,
};

export default SMF;
