import React from "react";
import Header from "../../components/Header";
import { Link, useParams, useLocation } from "react-router-dom";
import SkeletonImage from "../../components/SkeletonImage";
import SMF from "../../api";
import Gallery from "../../components/Gallery";
import Video from "../../components/Video";

const GalleryPage = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  React.useEffect(() => {}, [query.get("tab")]);
  React.useEffect(() => {
    onGet();
  }, []);

  function onGet() {
    SMF.Gallery.get()
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }
  return (
    <div>
      <Header />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <h1>
              <b>
                <span className="color-default">
                  Retrouver les moments fort
                </span>
                <br /> 4 ème édition
              </b>
            </h1>
          </div>
          <div className="col-lg-6">
            <div
              id="carouselExampleSlidesOnly"
              class="carousel slide"
              data-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="http://160.153.253.111:3333/api/v1/file/gallery_sites/1604400524441.jpg"
                    class="d-block w-100"
                    alt=" "
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="http://160.153.253.111:3333/api/v1/file/gallery_sites/1604400083700.jpg"
                    class="d-block w-100"
                    alt="  "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown-divider" />
      </div>
      <div className="container">
        <nav className="nav mt-1 ">
          <Link
            className={
              query.get("tab") == "photos"
                ? "nav-link b-secondary "
                : "nav-link black-color"
            }
            to={"/gallery?tab=photos"}
          >
            <b>Photos </b>
          </Link>
          <Link
            className={
              query.get("tab") == "videos"
                ? "nav-link b-secondary "
                : "nav-link black-color"
            }
            to={"/gallery?tab=videos"}
          >
            <b>Vidéos</b>
          </Link>
        </nav>
        <div className="row mt-4">
          {loader ? (
            <>
              <SkeletonImage className=" col-lg-4 mb-2" />
              <SkeletonImage className=" col-lg-4 mb-2" />
              <SkeletonImage className=" col-lg-4 mb-2" />
            </>
          ) : query.get("tab") == "photos" ? (
            data.map((el, key) => {
              if (el.type == "image") {
                return (
                  <Gallery
                    key={key}
                    className=" col-lg-4 mb-2"
                    name={el.name}
                    link={el.link}
                  />
                );
              }
            })
          ) : (
            data.map((el, key) => {
              if (el.type != "image") {
                return (
                  <Video
                    key={key}
                    className=" col-lg-4 mb-2"
                    name={el.name}
                    link={el.link}
                  />
                );
              }
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
