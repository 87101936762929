import React from "react";
import partner from "../../../assets/partener.svg";

const Partner = (props) => {
  const { name, content, logo } = props;
  return (
    <div {...props}>
      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img
              src={
                logo
                  ? "http://160.153.253.111:3333/api/v1/file/logos/" + logo
                  : partner
              }
              class="card-img"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{name}</h5>
              <p dangerouslySetInnerHTML={{ __html: content && content.slice(0, 20) }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
